.modal-primary .modal-header,
.modal-info .modal-header,
.modal-success .modal-header,
.modal-warning .modal-header,
.modal-danger .modal-header {
  color: #fff;
  border-radius: 5px 5px 0 0;
}
.modal-primary .modal-header {
/*  background: #428bca;*/
}
.modal-info .modal-header {
  background: #5bc0de;
}
.modal-success .modal-header {
  background: #5cb85c;
}
.modal-warning .modal-header {
  background: #f0ad4e;
}
.modal-danger .modal-header {
  background: #d9534f;
}
/*
.modal-danger .modal-body {
  background-color: #747474;
}

.modal-body{
  background-color: #747474;
}
*/
.modal-footer{
  margin-top:0px;
/*
  background-color: #747474;
*/
}

.modal-window-98percent .modal-dialog {
  min-width: 98%;
}

.modal-window-90percent .modal-dialog {
  min-width: 90%;
}

.modal-window-80percent .modal-dialog {
  min-width: 80%;
}
