#lima-system .row#nav_buttons {
    margin-top: 1em;
    margin-bottom: 1em;
}

#lima-system .fade {
    transition: all linear 100ms;
    opacity: 1;
}

#lima-system .fade.ng-hide {
    opacity: 0;
}
