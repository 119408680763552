
/**
 * Bootstrap Fonts
 */

@font-face {
    font-family: 'Glyphicons Halflings';
    src: url('../vendor/bootstrap/fonts/glyphicons-halflings-regular.eot');
    src: url('../vendor/bootstrap/fonts/glyphicons-halflings-regular.eot?#iefix') format('embedded-opentype'),
    url('../vendor/bootstrap/fonts/glyphicons-halflings-regular.woff') format('woff'),
    url('../vendor/bootstrap/fonts/glyphicons-halflings-regular.ttf') format('truetype'),
    url('../vendor/bootstrap/fonts/glyphicons-halflings-regular.svg#glyphicons_halflingsregular') format('svg');
}

/**
 *Font Awesome Fonts
 */

@font-face {
    font-family: 'FontAwesome';
    src: url('../vendor/font-awesome/fonts/fontawesome-webfont.eot?v=4.1.0');
    src: url('../vendor/font-awesome/fonts/fontawesome-webfont.eot?#iefix&v=4.1.0') format('embedded-opentype'),
    url('../vendor/font-awesome/fonts/fontawesome-webfont.woff?v=4.1.0') format('woff'),
    url('../vendor/font-awesome/fonts/fontawesome-webfont.ttf?v=4.1.0') format('truetype'),
    url('../vendor/font-awesome/fonts/fontawesome-webfont.svg?v=4.1.0#fontawesomeregular') format('svg');
    font-weight: normal;
    font-style: normal;
}

/**
 * App-wide Styles
 */

.browsehappy {
    margin: 0.2em 0;
    background: #ccc;
    color: #000;
    padding: 0.2em 0;
}

body {
    padding-top:60px;
}

body > div.tooltip-full > div.tooltip-inner {
    max-width: 400px;
    white-space: pre-wrap;
    overflow-wrap: break-word;
    word-wrap: break-word;
    overflow: auto;
    text-overflow: clip;
    text-align: left;
}

body > div.tooltip-full-large > div.tooltip-inner {
    max-width: 400px;
    font-size: 16px;
    white-space: pre-wrap;
    overflow-wrap: break-word;
    word-wrap: break-word;
    overflow: auto;
    text-overflow: clip;
    text-align: left;
}
