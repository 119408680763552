
/* sort arrows of table */
#lima-system-status .st-sort-ascent:before{
    content: '\25B2';
}

#lima-system-status .st-sort-descent:before{
    content: '\25BC';
}

#lima-system-status .row#nav_buttons {
    margin-top: 1em;
    margin-bottom: 1em;
}
