.editcheckbox {
  -ms-transform: scale(1.5, 1.5);
  -webkit-transform: scale(1.5, 1.5);
  transform: scale(1.5, 1.5);
}
.custom-template-cell {
  color: black;
}
.custom-template-cell > a {
  color: black;
}
.modal-fit .modal-dialog {
  width: 100%;
  height: 100%;
    padding: 10px;
}

.modal-fit .modal-body {
  width: 100%;
  height: 100%;
  padding: 10px;
}

.glyphicon-refresh-animate {
  -animation: spin .7s infinite linear;
  -webkit-animation: spin2 .7s infinite linear;
}

@-webkit-keyframes spin2 {
  from { -webkit-transform: rotate(0deg);}
  to { -webkit-transform: rotate(360deg);}
}

@keyframes spin {
  from { transform: scale(1) rotate(0deg);}
  to { transform: scale(1) rotate(360deg);}
}

iframe {
  width: 1px;
  min-width: 100%;
}


.img_outer {
  float: left;
  width: 200px;
  height: 200px;
  margin-right: 20px;
  margin-bottom: 0px;
  }
  .img_outer_small {
    float: left;
    width: 100px;
    height: 100px;
    margin-right: 10px;
    margin-bottom: 10px;
    }
    .img_inner {
  display: table-cell;
  vertical-align: middle;
  width: 200px;
  height: 200px;
  }
  .img_inner img {
  display: block;
  margin: 0 auto;
  max-width: 200px;
  max-height: 200px;
  }

  .img_inner_small img {
    display: block;
    margin: 0 auto;
    max-width: 100px;
    max-height: 100px;
    }
  ul.image_list {
    padding: 0px;
  }

  ul.image_list li {
    /* liが横並びになるようにします。 */
    display: inline-block;
  }
  ul.image_list li .image_box {
    width: 200px;
    height: 240px;
    /* チェックボックスの親要素にrelativeを指定しておきます。 */
    position: relative;
  }
  ul.image_list li .image_box_small {
    width: 100px;
    height: 100px;
    /* チェックボックスの親要素にrelativeを指定しておきます。 */
    position: relative;
  }
  .image_box .thumbnail {
    border: 6px solid #00000000;
    object-fit: contain;
    /* object-position:center center;*/
    width: 100%;
    height: 100%;
    /* 画像にカーソルを合わせたときに、
    カーソルが指のマークになるようにします。 */
    box-sizing: border-box;
    cursor: pointer;
    display: block;

  }
  .image_box .thumbnail2 {
    border: 6px solid #fff;
    object-fit: contain;
    /* object-position:center center;*/
    width: 100%;
    height: 100%;
    /* 画像にカーソルを合thumbnailわせたときに、
    カーソルが指のマークになるようにします。 */
    box-sizing: border-box;
    cursor: pointer;
    display: block;
    margin-bottom: 0px;
    /* background-color: #fff; */
    background-color:transparent;

  }



  .image_box_small .thumbnail {
    object-fit: contain;
    /* object-position:center center;
    width: 100%; */
    height: 100%;
    /* 画像にカーソルを合わせたときに、
    カーソルが指のマークになるようにします。 */
    cursor: pointer;
  }
  .image_box .thumbnail.checked {
    /* チェックが入った状態だと、枠が表示されるようにします。 */
    border: 6px solid #00000000;
    /* 線をwidthとheightに含めるようにします。 */
    box-sizing: border-box;
  }
  .image_box .disabled_checkbox {
    /* チェックボックスの位置は絶対位置にします。 */
    position: absolute;
    /* チェックボックスは、親要素の右上から12pxの位置に配置します。 */
    top: 12px;
    right: 12px;
    /* チェック前は非表示にしておきます。 */
    display: block;
    /* none; */
    /* チェックボックスを大きくします。 */
    transform: scale(2);
    /* チェックボックスにカーソルを合わせたときに、
    カーソルが指のマークになるようにします。 */
    cursor: pointer;
  }
  .image_box .thumbnail.checked + .disabled_checkbox {
    /* 画像にcheckedクラスが指定されたときは、
    チェックボックスの非表示を解除します。 */
    display: block;
  }
  .image_box .thumbnail2.checked + .disabled_checkbox {
    /* 画像にcheckedクラスが指定されたときは、
    チェックボックスの非表示を解除します。 */
    display: block;
  }

  .imageDiv2 {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  .image1 {
    object-fit: contain;
    width      : 400px;
    height     : 400px;
  }
  .imagetext1 {
    font-size      : 30px;
    /* font-family    : "Noto Sans CJK JP Regular"; */
    color          : gray;
    line-height    : 1.0em;
    letter-spacing : 0.0em;

    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%,-50%);
    -webkit-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);
    margin:0;
    padding:20px;

    /* width: 1080px; */
    /* max-height : 960px; */
    background-color:  rgba(255,255,255,0.5);
  }

  .opacity{
    opacity:0.6;
  }

  /*  */
  .page {
    padding: 15px 0 0;
  }

  .bmd-modalButton {
    display: block;
    margin: 15px auto;
    padding: 5px 15px;
  }

  .close-button {
    overflow: hidden;
  }

  .bmd-modalContent {
    box-shadow: none;
    background-color: transparent;
    border: 0;
  }

  .bmd-modalContent .close {
    font-size: 30px;
    line-height: 30px;
    padding: 7px 4px 7px 13px;
    text-shadow: none;
    opacity: .7;
    color:#fff;
  }

  .bmd-modalContent .close span {
    display: block;
  }

  .bmd-modalContent .close:hover,
  .bmd-modalContent .close:focus {
    opacity: 1;
    outline: none;
  }

  .bmd-modalContent iframe {
    display: block;
    margin: 0 auto;
  }

  .selectedRow{
    background:black;
    color:white;
  }

  .placeholder{
    border:1px dotted #ccc;
    height:30px;
    background-color:#eee;
  }

  .dropdown-menu .sub-menu {
    left: 100%;
    position: absolute;
    top: 0;
    visibility: hidden;
    margin-top: -1px;
}

.dropdown-menu li:hover > .sub-menu {
    visibility: visible;
    display: block;
}

/* ツールチップのスタイル */
.tooltip-inner {
  max-width: 500px !important;
}


.wrapframe {
  position: relative;
}

.overlayframe {
  position: absolute;
  top: 0;
  left: 0;
}




.color_wrapper {
  background-color: black;
  display: inline-block;
  visibility: hidden;
}

.color_wrapper::before {
  content: "";
  position: absolute;
  border-radius: 3px;
  outline: black solid 2px;
  border: white solid 3px;
  height: 2rem;
  width: 5rem;
  pointer-events: none;
  background-color: inherit;
  visibility: visible;
  box-sizing: border-box;
}

.color_picker {
  opacity: 0;
  height: 2rem;
  width: 5rem;
  box-sizing: border-box;
  pointer-events: initial;
  visibility: visible;
}


.color_picker_alpha {
  filter: grayscale(100%);
  visibility: visible;
}

.content_select {
width: 350px; /* 要素の横幅を指定 */
white-space: nowrap; /* 横幅のMAXに達しても改行しない */
overflow: hidden; /* ハミ出した部分を隠す */
text-overflow: ellipsis; /* 「…」と省略 */
-webkit-text-overflow: ellipsis; /* Safari */
-o-text-overflow: ellipsis; /* Opera */
}

.iframe_youtube{
  pointer-events:none;
}