table#users-table td {
    vertical-align: middle;
}
table#users-table td.right-cell {
    text-align: right;
}
.ctrl-btn {
    margin-left: 0.5em;
}

/* sort arrows */
#lima-user .st-sort-ascent:before{
    content: '\25B2';
}

#lima-user .st-sort-descent:before{
    content: '\25BC';
}

#lima-user .short-padding{
    padding: 2px;
}

#lima-user .row#nav_buttons {
    margin-top: 1em;
    margin-bottom: 1em;
}