
#rafale-edit .containThumb {
    width: 64px;
    height: auto;
    background-color:#000;
    display: inline-block;
}
#rafale-edit .divthumb {
    width: 64px;
    height: 36px;
    background-color:#000;
    display: table-cell;
    vertical-align: middle;
}
#rafale-edit .containThumb32 {
    width: 24px;
    height: auto;
    background-color:#000;
    display: inline-block;
}
#rafale-edit .divthumb32 {
    width: 24px;
    height: 13px;
    background-color:#000;
    display: table-cell;
    vertical-align: middle;
}

#rafale-edit .containThumb_still {
    width: 120px;
    height: auto;
    /* background-color:#000; */
    display: inline-block;
    max-width: 120px;
    cursor:pointer;
}
#rafale-edit .divthumb_still {
    width: 120px;
    height: 90px;
    /* background-color:#000; */
    display: table-cell;
    vertical-align: middle;
    margin: 0 auto;
}

#rafale-edit .editcheckbox {
    -ms-transform: scale(1.5, 1.5);
    -webkit-transform: scale(1.5, 1.5);
    transform: scale(1.5, 1.5);
}
#rafale-edit #tableInOut td ,
#rafale-edit #tableInOutEnc td
{
    height: 36px;
    text-align: center;
    vertical-align: middle;
    padding-top:1px;
    padding-bottom:1px;
    padding-left:0;
    padding-right:0;
}
#rafale-edit #tableInOut th ,
#rafale-edit #tableInOutEnc th
{
    height: 30px;
    text-align: center;
    vertical-align: middle;
}

#rafale-edit .reverseIcon
{
    -moz-transform: scaleX(-1);
    -o-transform: scaleX(-1);
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
    filter: FlipH;
    -ms-filter: "FlipH";


}
#rafale-edit .halfbtn
{
    -moz-transform: scaleX(-0.5);
    -o-transform: scaleX(-0.5);
    -webkit-transform: scaleX(-0.5);
    transform: scaleX(-0.5);
    filter: FlipH;
    -ms-filter: "FlipH";
    transform-origin: left top;

}

#rafale-edit #scrollStill{
    overflow-y:auto; /*scroll;*/
}

/*スクロール用*/
#rafale-edit #tableInOut thead.scrollHead,tbody.scrollBody,
#rafale-edit #tableInOutEnc thead.scrollHeadEnc,tbody.scrollBodyEnc
{
    display:block;
}
#rafale-edit #tableInOut tbody.scrollBody,
#rafale-edit #tableInOutEnc tbody.scrollBodyEnc
{
        overflow-y:auto; /*scroll;*/
}

/*幅調整*/
#rafale-edit #tableInOut td,th ,
#rafale-edit #tableInOutEnc td,th{
        table-layout:fixed;
}

#rafale-edit #tableInOut th ,
#rafale-edit #tableInOutEnc th{
    border-bottom:0px;
}

#rafale-edit .td_check{
    width:30px;
}
#rafale-edit .td_img{
    width:64px;
}
#rafale-edit .td_img32{
    width:24px;
}

#rafale-edit-navbar-edit a:hover,
#rafale-edit-navbar-edit a:focus
{
    text-decoration: none;

    color:#007deb;
    background-color: transparent;
    cursor: pointer;
}
#rafale-edit-navbar-edit a
{
    color:#fff;
}


.transcode-preset-modal-window .modal-dialog {
    width:90%;
 }