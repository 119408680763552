#lima-userGroup .short-padding{
    padding: 2px;
}

/* sort arrows */
#lima-userGroup .st-sort-ascent:before{
    content: '\25B2';
}

#lima-userGroup .st-sort-descent:before{
    content: '\25BC';
}

#lima-userGroup .row#nav_buttons {
    margin-top: 1em;
    margin-bottom: 1em;
}
